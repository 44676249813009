<template>
  <a-spin :spinning="spinning">
    <!-- <react-table :table-data="tableData"
                     :total-items="totalItems"
                     :showTotal="showTotalFun"
                     :columns="columns"
                     :scroll="{ x: 1300 }"
                     :hideRowSelection="true"
                     :is-full="true"
                     :current-page="pageNumber"
                     @emitRefreshData="clearFilterAndRefresh"
                     @emitSelectChange="updateSelectChange"
                     @emitOnPageChange="updatePageChange"
                     @emitShowSizeChange="updateShowSizeChange"
                     @emitSortData="updateSortData"> -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hideRowSelection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="24">
        <a-col class="gutter-row" :span="5">
          <a-input
            v-model.trim="searchDto.filterText"
            :placeholder="l('收款人姓名/手机号/身份证号/银行卡号')"
            style="width: 100%"
            allowClear
          ></a-input>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-month-picker
            v-model="searchDto.BatchMonth"
            :placeholder="l('SalaryMonth')"
            valueFormat="YYYY-MM"
            style="width: 100%"
          />
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select v-model="searchDto.isWithdraw" style="width: 98%" :placeholder="l('是否提现记录')" allowClear>
            <a-select-option value="true">提现记录</a-select-option>
            <a-select-option value="false">非提现记录</a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select
            v-model="searchDto.CustomerId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :placeholder="l('PleaseSelectCustomer')"
            allowClear
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select
            v-model="searchDto.PayCompanyId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :placeholder="l('PlaceSelectPayCompany')"
            allowClear
          >
            <a-select-option
              v-for="item in PayCompanys"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col class="gutter-row" :span="18" align="left">
          <a-button @click="ExportDataList" type="danger"
            ><a-icon type="arrow-down" />
            {{ l("ExportUserDownloadConfig") }}</a-button
          >
        </a-col>
        <a-col class="gutter-row" :span="6" align="right">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.SalarySumPermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearFilterAndRefresh"> {{ l("Reset") }}</a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
// import ReactTable from "@/components/react-table";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { fileDownloadService } from "@/shared/utils";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data: function () {
    var date = new Date()
    let str = moment(date).format('YYYY-MM')
    return {
      spinning: false,
      searchDto: {
        PersonName: "",
        BatchMonth: str,
        Status: undefined,
        CustomerId: undefined,
        PayCompanyId: undefined,
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 50,
        filterText: "",
        isWithdraw: undefined
      },
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      SalaryStatus: [
        // { label: this.l("PleaseSelectStatus"), value: -1 },
        { label: this.l("WaitForSend"), value: 0 },
        { label: this.l("SalarySuccess"), value: 1 },
        { label: this.l("SalaryFailed"), value: 2 },
        { label: this.l("ReSalaried"), value: 3 },
      ],
      columns: [
        {
          title: this.l("PayCompany"),
          dataIndex: "payCompanyName",
          align: "center",
          // fixed: "left",
          width: 200,
          ellipsis: true,
        },
        {
          title: this.l("NameSurname"),
          dataIndex: "userName",
          align: "center",
          // fixed: "left",
          // width: 100,
          scopedSlots: { customRender: "userName" },
        },
        {
          title: this.l("PayAmount"),
          dataIndex: "amount",
          align: "center",
          // width: 150,
          ellipsis: true,
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: this.l("ProjectUserIdCard"),
          dataIndex: "idCard",
          align: "center",
          width: 200,
          ellipsis: true,
        },
        {
          title: this.l("BankAccountNo"),
          dataIndex: "bankNum",
          align: "center",
          width: 200,
          ellipsis: true,
        },
        {
          title: this.l("BankName"),
          dataIndex: "bankName",
          align: "center",
          width: 150,
          ellipsis: true,
        },
        // {
        //   title: this.l("SalaryDate"),
        //   dataIndex: "payTime",
        //   align: "center",
        //   customRender: (text) => {
        //     return text.replaceAll("T", " ").split(".")[0];
        //   },
        // },
        {
          title: this.l("ProjectHeaderCustomerName"),
          dataIndex: "customerName",
          align: "center",
          width: 240,
          ellipsis: true,
        },
        {
          title: this.l("发放月份"),
          dataIndex: "grantMouth",
          align: "center",
          ellipsis: true,
          // sorter: true,
        },
        {
          title: this.l("Status"),
          dataIndex: "statusName",
          //   fixed: "right",
          align: "center",
          // width: 110,
          // customRender: (text) => {
          //   return <a-tag color="#87d068">{{ text }}</a-tag>;
          // },
        },
        {
          title: this.l("是否提现"),
          dataIndex: "withdrawal",
          align: "center"
        },
      ],
      PayCompanys: [], // { value: "", label: this.l("PlaceSelectPayCompany") }
      Customers: [], // { value: "", label: this.l("PleaseSelectCustomer") }
    };
  },
  methods: {
    /**
     * ������
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          method: "GET",
        })
        .then((res) => {
          // this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * ��ȡ�ͻ�
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            // this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    search() {
      this, (this.pageNumber = 1);
      this.searchDto.SkipCount = 0;
      this.getData();
    },

    getData() {
      this.spinning = true;
      let options = {
        url:
          this.$apiUrl + "/api/services/app/Salary/GetDistributionSummaryPaged",
        method: "GET",
        params: Object.assign(
          {
            ...this.searchDto,
          },
          {
            realName: this.searchDto.PersonName,
            yearMonth: this.searchDto.BatchMonth,
            status: this.searchDto.Status ? this.searchDto.Status : null,
            customerId: this.searchDto.CustomerId
              ? this.searchDto.CustomerId
              : null,
            companyId: this.searchDto.PayCompanyId
              ? this.searchDto.PayCompanyId
              : null,
            sorting:
              this.searchDto.Sorting == "" ? null : this.searchDto.Sorting,
            skipCount: this.searchDto.SkipCount,
            maxResultCount: this.searchDto.MaxResultCount,
          }
        ),
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.searchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.searchDto.MaxResultCount + 1,
              this.pageNumber * this.searchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
                // grantMouth: moment(item.grantMouth).format("YYYY-MM-DD"),
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    ExportDataList() {
      this.spinning = true;
      let options = {
        url:
          this.$apiUrl + "/api/services/app/Salary/ExportDistributionSummary",
        method: "GET",
        params: {
          realName: this.searchDto.PersonName,
          yearMonth: this.searchDto.BatchMonth,
          status: this.searchDto.Status ? this.searchDto.Status : null,
          customerId: this.searchDto.CustomerId
            ? this.searchDto.CustomerId
            : null,
          companyId: this.searchDto.PayCompanyId
            ? this.searchDto.PayCompanyId
            : null,
          sorting: this.searchDto.Sorting == "" ? null : this.searchDto.Sorting,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            fileDownloadService.downloadTempFile(res.data);
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    clearFilterAndRefresh() {
      console.log(this.searchDto);
      this.searchDto = {
        PersonName: "",
        BatchMonth: "",
        Status: undefined,
        CustomerId: undefined,
        PayCompanyId: undefined,
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 50,
        filterText: "",
        isWithdraw: undefined
      };
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.searchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.searchDto.SkipCount = 0;
      this.searchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.searchDto.SkipCount = (page - 1) * pageSize;
      this.searchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.searchDto.PersonName = "";
      this.searchDto.BatchMonth = "";
      this.searchDto.Status = undefined;
      this.searchDto.CustomerId = undefined;
      this.searchDto.PayCompanyId = undefined;
      this.searchDto.Sorting = "";
      this.getData();
    },
  },
  created() {
    this.getAllPayCompany();
    this.getAllCutomer();
  },
  mounted() {
    var date = new Date()
    let str = moment(date).format('YYYY-MM')
    this.searchDto.BatchMonth = str;
    this.getData();
    let self = this;
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e
      if (ev.keyCode === 13) {
        self.getData()
      }
    };
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
.gutter-row{
  padding: 0 0 10px 5px;
}
</style>
